import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function MtCookAorakiQueenstownChristchurch(props) {
  return (
    <>
      <SEO title="Mt Cook Tour - Queenstown to Christchurch - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/sliders/mount-cook-mountain-sunset.jpg")}
                alt=""
                class="first image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/mount-cook-mountain-divide.jpg")}
                alt=""
                class="image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/mount-cook-mount-sefton.jpg")}
                alt=""
                class="last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={true}
        hasSliderBlurLarge={true}
        content={
          <>
            <BookingForm tourCode="AWQMC" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Mt Cook - Queenstown Return</h1>{" "}
                {/* TODO XXX FIXME NOCOMMIT */}
                <h3>
                  See the unparalleled natural beauty of Aoraki/Mt Cook National
                  Park
                </h3>
                <div class="highlights">
                  <ul>
                    <li>See NZ's highest mountain - Aoraki/Mt Cook</li>
                    <li>Spectacular views from the Lindis Pass</li>
                    <li>Explore Aoraki/Mt Cook village</li>
                    <li>Expert driver commentary</li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <p>
                      Take a full day tour from Queenstown to Mt Cook,
                      descending back through the Southern Alps and continuing
                      north to Christchurch.
                    </p>
                    <p>
                      From Queenstown, cross over the spectacular{" "}
                      <strong>Lindis Pass</strong> with its amazing views, and
                      through the tawny tussock of the{" "}
                      <strong>Mackenzie Country</strong>. Named after a
                      notorious sheep thief, this beautiful area has long been
                      used for high country sheep farming. It's also home to the
                      beautiful lakes Pukaki and Tekapo, both famous for their
                      stunning blue colour created by tiny particles of 'glacier
                      flour'.
                      <br />
                      <br />
                      Continue into Aoraki/Mount Cook National Park, home to New
                      Zealand's highest mountain as well as its longest glacier.
                      On arrival at Mt Cook Village, you'll have plenty of time
                      to enjoy lunch (own expense) and explore the gorgeous
                      alpine community nestled at the foot of the mountains.
                      Soak up the scenery on terra firma, or choose to take a
                      scenic helicopter or ski plane flight (check with your
                      driver on the day). This an additional cost and weather
                      permitting.
                      <br />
                      <br />
                      In the afternoon, travel back past{" "}
                      <strong>Lake Pukaki</strong> to{" "}
                      <strong>Lake Tekapo</strong>, where you can view the
                      picturesque <strong>Church of the Good Shepherd</strong>{" "}
                      against the lake's vivid blue backdrop. We then continue
                      through the diverse farmland of the{" "}
                      <strong>Canterbury Plains</strong> to{" "}
                      <strong>Christchurch</strong>, the Garden City, where your
                      tour concludes.
                    </p>
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
              <div class="GSRelatedProducts clearfix">
                <h2 class="tour">Tours You May Also Like</h2>
                {/* ... */}
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-mt-cook-tour">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/thumbs/milford_chc_mt_cook.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Milford Sound + Mount Cook</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$285.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-mt-cook-tour">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-christchurch-queenstown">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/thumbs/milford_chc_zqn.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Christchurch - Queenstown Tavel + Milford Sound</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$199.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-christchurch-queenstown">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
                {/* ... */}
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default MtCookAorakiQueenstownChristchurch
